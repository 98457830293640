import './../styles/main.scss';
import Cookiebar from './cookiebar';

// CustomEvent polyfill
// source: https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
(function() {
  if (typeof window.CustomEvent === "function") return false

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: undefined }
    var evt = document.createEvent("CustomEvent")
    evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail)
    return evt
  }

  CustomEvent.prototype = window.Event.prototype

  window.CustomEvent = CustomEvent
})()

/*
 * Cookie Class
 */
class Cookie {
  static start() {
    return new Cookie();
  }

  constructor() {
    Promise
      .all([
        Cookie.domReady(),
      ])
      .then(this.init.bind(this));
  }

  static domReady() {
    return new Promise(resolve => {
      document.addEventListener('DOMContentLoaded', resolve);
    });
  }

  init() {
    try {
      Cookiebar.load();
      window.__cookieBar__ = Cookiebar;
    } catch (err) {
      console.error(err);
    }
  }
}

Cookie.start();
