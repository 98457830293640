import docCookies from '../vendor/cookies';

export default {
  load(edit) {
    const savedCookies = docCookies.getItem('epic-cookie-prefs');

    if (savedCookies && edit !== true) {
      return;
    }

    this.$outer = document.querySelector('[data-component="cookiebar-outer"]');
    this.$el = document.querySelector('[data-component="cookiebar"]');
    this.$root = this.$outer || this.$el;
    this.$refs = {
      more: document.querySelectorAll('[data-ref="cookiebar.more"]'),
      actions: document.querySelector('[data-ref="cookiebar.actions"]'),
      details: document.querySelector('[data-ref="cookiebar.details"]'),
      moreTrigger: document.querySelectorAll(
        '[data-ref="cookiebar.moreTrigger"]'
      ),
      validate: document.querySelector('[data-ref="cookiebar.validate"]'),
    };

    this.i18n = {
      hidetext: this.$el.getAttribute('data-cookiebar-hidetext'),
      showtext: this.$el.getAttribute('data-cookiebar-showtext'),
    };

    this.isOpen = {
      details: false,
    };

    this.mores = {};

    [...this.$refs.more].forEach(m => {
      const name = m.getAttribute('data-name');

      this.isOpen[name] = false;
      this.mores[name] = m;
    });

    this.onDetailsClick = this.onDetailsClick.bind(this);
    this.onMoreClick = this.onMoreClick.bind(this);
    this.onMoreTriggerClick = this.onMoreTriggerClick.bind(this);
    this.onValidateClick = this.onValidateClick.bind(this);

    [...this.$refs.moreTrigger].forEach(m => {
      m.addEventListener('click', this.onMoreTriggerClick);
    });

    this.$refs.details.addEventListener('click', this.onDetailsClick);
    this.$el.addEventListener('submit', this.onValidateClick);

    this.show();
  },

  onClick(e, target) {
    e.preventDefault();

    const name = target.getAttribute('data-name');

    switch (target.getAttribute('data-ref')) {
      case 'cookiebar.details':
        this[this.isOpen.details ? 'closeDetails' : 'openDetails']();
        break;

      case 'cookiebar.moreTrigger':
        this[this.isOpen[name] ? 'closeMore' : 'openMore'](name);
        break;

      case 'cookiebar.validate':
        break;

      default:
    }
  },

  onDetailsClick(e, target) {
    e.preventDefault();
    this[this.isOpen.details ? 'closeDetails' : 'openDetails']();
  },

  onMoreClick(e, target) {
    this[this.isOpen.details ? 'closeDetails' : 'openDetails']();
  },

  onMoreTriggerClick(e) {
    const name = e.currentTarget.getAttribute('data-name');

    this[this.isOpen[name] ? 'closeMore' : 'openMore'](name);
  },

  onValidateClick(e, target) {
    e.preventDefault();

    this.hide();
  },

  openDetails() {
    this.$refs.details.innerHTML = this.i18n.hidetext;
    this.isOpen.details = true;

    if (window.TweenMax) {
      TweenMax.set(this.$refs.actions, {
        height: 'auto',
      });

      TweenMax.from(this.$refs.actions, 0.3, {
        height: 0,
        ease: Power4.easeOut,
        onComplete: () => {
          this.$refs.actions.classList.add('is-open');
          TweenMax.set(this.$refs.actions, {
            clearProps: 'all',
          });
        },
      });
    }

    this.$refs.actions.classList.add('is-open');
  },

  closeDetails() {
    this.$refs.details.innerHTML = this.i18n.showtext;
    this.isOpen.details = false;

    if (window.TweenMax) {
      TweenMax.to(this.$refs.actions, 0.3, {
        height: 0,
        ease: Power4.easeOut,
        onComplete: () => {
          TweenMax.set(this.$refs.actions, {
            clearProps: 'all',
          });
        },
      });
    }

    this.$refs.actions.classList.remove('is-open');
  },

  openMore(name) {
    const more = this.mores[name];

    this.isOpen[name] = true;

    if (window.TweenMax) {
      TweenMax.set(more, {
        height: 'auto',
      });

      TweenMax.from(more, 0.3, {
        height: 0,
        ease: Power4.easeOut,
        onComplete: () => {
          more.classList.add('is-open');
          TweenMax.set(more, {
            clearProps: 'all',
          });
        },
      });
    }

    more.classList.add('is-open');
  },

  closeMore(name) {
    const more = this.mores[name];

    this.isOpen[name] = false;

    if (window.TweenMax) {
      TweenMax.to(more, 0.3, {
        height: 0,
        ease: Power4.easeOut,
        onComplete: () => {
          more.classList.remove('is-open');
          TweenMax.set(more, {
            clearProps: 'all',
          });
        },
      });
    }

    more.classList.remove('is-open');
  },

  show() {
    if (window.TweenMax) {
      this.$root.style.opacity = 0;
      this.$root.style.display = 'block';

      TweenMax.to(this.$root, 0.3, {
        opacity: 1,
      });
    } else {
      this.$root.style.display = 'block';
    }
  },

  hide() {
    if (window.TweenMax) {
      TweenMax.to(this.$root, 0.3, {
        opacity: 0,
        onComplete: () => {
          this.$root.style.display = 'none';
        },
      });
    } else {
      this.$root.style.display = 'none';
    }

    const { prefs, prefsAll } = this.save();

    this.fetch(prefs).then(data => {
      this.inject(data);
      this.dispatch([prefsAll]);
    });
  },

  save() {
    // Get cookie prefs
    const checkboxes = this.$el.querySelectorAll(
      'input[type="checkbox"][name="cookie_category[]"]'
    );
    const prefs = [];
    const prefsAll = {};

    Array.prototype.forEach.call(checkboxes, ({ value, checked }) => {
      checked && prefs.push(value);
      prefsAll[value] = checked;
    });

    // Write cookie-prefs
    const d = new Date();
    d.setTime(d.getTime() + 365 * 24 * 3600 * 1000);
    docCookies.setItem('epic-cookie-prefs', JSON.stringify(prefs), d, '/');

    return {
      prefs,
      prefsAll,
    };
  },

  fetch(prefs) {
    const body = new FormData();
    const headers = new Headers();
    const action = this.$el.querySelector('[name="action"]').value;
    const url = this.$el.getAttribute('action');

    headers.append('cache-control', 'no-cache');
    headers.append('X-Doing-Ajax', 'true');
    body.set('action', action);
    body.set('epic-cookie-prefs', JSON.stringify(prefs));

    return fetch(url, {
      method: 'POST',
      body,
      headers,
      credentials: 'same-origin',
    }).then(response => response.json());
  },

  inject(data) {
    const re = /(?:<!--.+-->)*(?:<(?:noscript|script)>)*([\s\S]+?)(?:<\/(?:noscript|script)>)*(?:<!--.+-->)*/gm;

    data.forEach(d => {
      const tag = document.createElement('script');

      tag.innerHTML = d.replace(re, '$1');
      document.body.insertBefore(tag, document.body.children[0]);
    });
  },

  dispatch(prefsAll) {
    // Dispatch custom event
    const consentEvent = new CustomEvent('cookieConsent', { detail: prefsAll });

    document.dispatchEvent(consentEvent);
  },
};
